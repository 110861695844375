import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { NextRouter } from 'next/router';

dayjs.extend(timezone);
dayjs.extend(utc);

const maintenanceStart = process.env.NEXT_PUBLIC_MAINTENANCE_START || '';
const maintenanceEnd = process.env.NEXT_PUBLIC_MAINTENANCE_END || '';
const formatRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}$/;

const MAINTENANCE_URL = 'https://maintenance.smon.swingby.us/';
const allowedPagePathnames = [
  '/ticket/basic-ticket',
  '/ticket/payment',
  '/payment',
  '/ticketbox/order',
  '/login',
  '/signup',
];

const checkDateFormat = (dateToCheck: string) => formatRegex.test(dateToCheck);

export const maintenanceSchedule = {
  maintenanceStart: checkDateFormat(maintenanceStart) ? maintenanceStart : '',
  maintenanceEnd: checkDateFormat(maintenanceEnd) ? maintenanceEnd : '',
};

export const checkMaintenance = (url: string, router: NextRouter) => {
  if (!(maintenanceStart && maintenanceEnd)) return;
  const isMaintenanceTime = dayjs().isAfter(dayjs(maintenanceStart)) && dayjs().isBefore(dayjs(maintenanceEnd));
  const isPassedShutdownTime = dayjs().isAfter(dayjs(maintenanceStart).add(5, 'm'));
  const isAllowedPages = allowedPagePathnames.some((path) => url.startsWith(path));

  if (isMaintenanceTime) {
    if (isPassedShutdownTime || !isAllowedPages) return router.push(MAINTENANCE_URL);
  }
};
