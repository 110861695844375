import { useRouter } from 'next/router';
import Image from 'next/image';
import styled from '@emotion/styled';
import { memo } from 'react';
import SmobLogo from '../../public/smob-desktop-bi.png';
import SmobText from '../../public/smob-desktop-text.png';

const PcWrapper = () => {
  const router = useRouter();

  return (
    <div className="pc-wrapper">
      <div className="pc-branch-bi" onClick={() => router.push('/')}>
        <Image unoptimized src={SmobLogo} width={137} height={56} alt="Smob BI" />
      </div>
      <div className="pc-promo">
        <Image unoptimized src={SmobText} width={260} height={173} alt="Smon Desc" />
      </div>

      <div className="pc-branch-info">
        <Term onClick={() => router.push('/terms/service')}>서비스 이용약관</Term> &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        <Term onClick={() => router.push('/terms/privacy')}>개인정보처리방침</Term> &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        <Term onClick={() => router.push('/terms/park')}>파크 이용약관</Term>
        <br />
        <br />
        상호: 위피크(주)
        <br />
        주소: 경기도 성남시 분당구 대왕판교로 660
        <br />
        대표이사: 김정준 | 사업자등록번호: 214-87-97600
        <br />
        통신판매업신고: 제2016-경기하남-0426호 | 특허출원번호: 10-2017-0115016
        <br />
        고객센터 및 단체문의: 1668-4832(유료)
        <br />
        상담시간: 평일 오전 9시~ 오후 5시(점심 12-1시 제외)
        <br />
        이메일주소: sportsmonster@wepeak.co.kr
      </div>
    </div>
  );
};

export default memo(PcWrapper);

const Term = styled.span`
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`;
