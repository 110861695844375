import { gql } from '@apollo/client';

export const QUERY_LOGGED_IN = gql`
  query isLoggedIn {
    isLoggedIn @client
  }
`;

export const QUERY_USER = gql`
  query user {
    user @client
  }
`;

export const QUERY_LOCAL_PARK = gql`
  query findCurrentPark {
    park @client
  }
`;

export const FRAGMENT_USER = gql`
  fragment userData on User {
    id
    uid
    cellPhone
    name
    nick
    password
    email
    type
    kakaoId
    appleId
    emailId
    birth
    gender
    profileImageUrl
    metaData {
      marketingTerm
      marketingTermTimeStamp
    }
  }
`;

export const QUERY_COUPONLIST = gql`
  query findUsableCoupons {
    result: userCouponFindMany(usableOnly: true) {
      id
      uid
      coupon {
        id
        name
        type
        status
        whereToUse
        discountMethod
        value
        absoluteValidity
        lifeTimeUnit
        lifeTimeValue
        lifeCount
        parks {
          uid
          name
        }
        compatibleTicketType {
          uid
          name
          park {
            uid
            name
          }
          metaData {
            tag
          }
        }
        compatibleProductType {
          uid
        }
        metaData {
          description
          imageUrl
        }
      }
      status
      beginAt
      issuedAt
    }
  }
`;

export const QUERY_ORDERLIST = gql`
  query orders($filter: FilterFindManyOrderInput) {
    result: orderFindMany(filter: $filter) {
      id
      uid
      userTickets {
        metaData {
          parentPackage
        }
        uid
        reservedAt
        ticket {
          name
          ageGroup
          metaData {
            tag
          }
        }
        status
        enterAt
      }
      userProducts {
        metaData {
          parentPackage
        }
        id
        uid
        product {
          price
          id
          name
          metaData {
            tag
            packageOriginalPrice
          }
        }
        status
      }
      userCoupons {
        uid
        owner {
          name
        }
        coupon {
          absoluteValidity
          lifeTimeUnit
          lifeTimeValue
          kioskExposureName
          type
        }
        status
      }
      payments {
        refundedAt
      }
      status
      park {
        uid
        name
      }
    }
  }
`;

export const QUERY_ORDER_BY_UID = gql`
  query order($uid: String!) {
    result: orderFindByUid(uid: $uid) {
      uid
      id
      userCoupons {
        uid
        status
        beginAt
        coupon {
          absoluteValidity
          lifeTimeValue
          lifeTimeUnit
          name
        }
      }
      userTickets {
        metaData {
          parentPackage
        }
        uid
        reservedAt
        ticket {
          id
          uid
          name
          price
          metaData {
            tag
          }
          ageGroup
        }
        discountCoupons {
          uid
          coupon {
            name
            value
            discountMethod
          }
        }
        status
      }
      userProducts {
        metaData {
          parentPackage
        }
        id
        uid
        price
        product {
          id
          uid
          name
          price
          metaData {
            packageOriginalPrice
            tag
          }
        }
        status
      }
      cachedPrice
      cachedPaidAmount
      payments {
        type
        totalPaidAmount
        refundedAt
        status
        paidAt
        paidAmount
      }
      paidAt
      status
      createdAt
      park {
        uid
        name
      }
      user {
        cellPhone
      }
    }
  }
`;

export const QUERY_ME = gql`
  query me {
    result: me {
      ...userData
    }
  }
  ${FRAGMENT_USER}
`;

export const QUERY_USERCOUPON = gql`
  query usercoupon($filter: FilterFindOneUserCouponInput) {
    result: userCouponFindByCouponUid(filter: $filter) {
      uid
      coupon {
        uid
        name
        whereToUse
        discountMethod
        value
        absoluteValidity
        lifeTimeUnit
        lifeTimeValue
        metaData {
          description
          imageUrl
        }
      }
      beginAt
      status
      remainLifeCount
    }
  }
`;

export const QUERY_EVENTLIST = gql`
  query eventList($filter: FilterFindManyEventInput) {
    result: eventPagination(filter: $filter) {
      items {
        id
        title
        subTitle
        thumbnail
        content
        createdAt
        exposure
        exposureEnd
        parks {
          uid
          name
        }
      }
    }
  }
`;

export const QUERY_EVENT = gql`
  query eventFindOne($id: MongoID) {
    result: eventPagination(filter: { _id: $id }) {
      items {
        id
        title
        subTitle
        thumbnail
        content
        createdAt
        exposure
      }
    }
  }
`;

export const QUERY_TIP = gql`
  query tipFindOne($id: MongoID) {
    result: tipPagination(filter: { _id: $id }) {
      items {
        id
        title
        subTitle
        content
        thumbnail
      }
    }
  }
`;

export const QUERY_TICKETS_ON_PURCHASE = gql`
  query tickets($filter: FilterFindManyTicketInput) {
    result: ticketFindMany(filter: $filter, sort: SALESENDDATE_ASC) {
      id
      uid
      name
      usingHours
      price
      metaData {
        tag
        description
      }
      salesStartDate
      salesEndDate
      salesTime
    }
  }
`;

export const QUERY_GET_PACKAGES = gql`
  query productFindMany($filter: FilterFindManyProductInput) {
    result: productFindMany(isPackage: true, filter: $filter, sort: SALESENDDATE_ASC) {
      id
      uid
      name
      price
      packageItems {
        coupon
        ticket
        product
        quantity
      }
      salesTime
      status
      park {
        uid
        name
      }
      onlineExposure
      metaData {
        tag
        packageOriginalPrice
        description
        packageUsingHours
      }
      salesStartDate
      salesEndDate
    }
  }
`;

export const QUERY_NEXT_HOLIDAY = gql`
  query getNextHoliday($filter: FilterFindManyHolidayInput) {
    result: holidayFindMany(filter: $filter) {
      holiday
      memo
      park {
        uid
      }
    }
  }
`;

export const GET_TIMETABLE = gql`
  query getTimeTable($parkUid: String!, $date: String!) {
    result: onlineReservationFindOrCreate(parkUid: $parkUid, date: $date) {
      park {
        id
        uid
        name
      }
      timeTable
      reservedAt
    }
  }
`;

export const GET_ATTRACTIONS = gql`
  query getAttractions($filter: FilterFindManyAttractionInput) {
    result: attractionFindMany(filter: $filter) {
      id
      uid
      name
      location
      zone
      floor
      reqHeight
      desc
      imageUrl
      thumbnail
      theme
      hashTag
      video
      score
      player
      park {
        uid
        name
      }
    }
  }
`;

export const GET_PLAY_RECORDS_INFINITE = gql`
  query getPlayRecords(
    $isInfinite: Boolean
    $page: Int
    $perPage: Int
    $sort: SortFindManySensorActivityInput
    $filter: FilterFindManySensorActivityInput
  ) {
    result: sensorActivityPagination(
      isInfinite: $isInfinite
      page: $page
      perPage: $perPage
      sort: $sort
      filter: $filter
    ) {
      items {
        id
        zone
        createdAt
        measureList
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
      }
    }
  }
`;

export const QUERY_BAND_INFO = gql`
  query getMyBand {
    result: bandFindOne {
      id
      uid
      nfcId
      userTicket {
        id
        uid
        enterAt
        ticket {
          park {
            name
          }
        }
      }
      user {
        id
        uid
      }
      connectedAt
      status
    }
  }
`;

export const QUERY_VIDEO = gql`
  query getVideo($filter: FilterFindManyVideoActivityInput, $sort: SortFindManyVideoActivityInput) {
    result: videoActivityFindMany(filter: $filter, sort: $sort) {
      id
      fileName
      zone
      park {
        name
      }
      isPlayed
      createdAt
    }
  }
`;

export const QUERY_VIDEO_RECORD_DATES = gql`
  query getVideoRecordDates($zone: [String]) {
    result: videoActivityFindDates(zone: $zone) {
      date
      park {
        name
      }
    }
  }
`;

export const QUERY_VIDEO_PAGINATION = gql`
  query getVideos(
    $isInfinite: Boolean
    $page: Int
    $perPage: Int
    $filter: FilterFindManyVideoActivityInput
    $sort: SortFindManyVideoActivityInput
  ) {
    result: videoActivityPagination(
      isInfinite: $isInfinite
      page: $page
      perPage: $perPage
      filter: $filter
      sort: $sort
    ) {
      count
      items {
        id
        fileName
        zone
        createdAt
      }
      pageInfo {
        currentPage
        perPage
        pageCount
        itemCount
        hasNextPage
      }
    }
  }
`;

export const QUERY_CLIENT = gql`
  query clientFindByUid($clientUid: String) {
    result: clientFindByUid(clientUid: $clientUid) {
      id
      uid
      name
    }
  }
`;

export const QUERY_CP_COUPON = gql`
  query b2bCouponFindMany($filter: FilterFindManyCouponInput) {
    result: couponFindMany(filter: $filter) {
      uid
      name
      price
      lifeTimeUnit
      lifeTimeValue
      deployLimitCount
      deployedCount
      usedCount
      parks {
        id
        uid
        name
      }
    }
  }
`;

export const QUERY_B2B_ORDERS = gql`
  query b2bOrderFindMany($B2BReservedNum: String, $sort: SortFindManyOrderInput, $filter: FilterFindManyOrderInput) {
    result: orderFindMany(B2BReservedNum: $B2BReservedNum, sort: $sort, filter: $filter) {
      id
      uid
      status
      cachedPrice
      cachedPaidAmount
      paidAt
      userCoupons {
        id
        uid
        beginAt
        usedAt
        status
        metaData {
          usedPark {
            id
            name
          }
        }
        coupon {
          name
          absoluteValidity
          lifeTimeUnit
          lifeTimeValue
        }
      }
    }
  }
`;

export const QUERY_PARKS = gql`
  query parkFindMany($filter: FilterFindManyParkInput) {
    result: parkFindMany(filter: $filter) {
      uid
      name
    }
  }
`;

export const QUERY_PARK = gql`
  query parkFindOne($filter: FilterFindOneParkInput) {
    result: parkFindOne(filter: $filter) {
      id
      uid
      name
    }
  }
`;

export const QUERY_PURCHASBLE_AMOUNT = gql`
  query reportUserCouponHistoryByCouponUid($uid: String!) {
    result: reportUserCouponHistoryByCouponUid(uid: $uid, isB2b: true) {
      totalCount
      usedCount
      cancelledCount
    }
  }
`;
