import { createTheme } from '@mui/material/styles';
import { Color } from '../constants';
import { css } from '@emotion/react';

export const GlobalStyle = css`
  * {
    touch-action: manipulation;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }

  #__next {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: calc(var(--vh, 1vh) * 100);
    max-width: 640px;
    min-width: 360px;
    background-color: white;
    z-index: 1;
  }
`;

export const GlobalStyleForCP = css`
  * {
    letter-spacing: -0.05rem !important;
    font-family: Spoqa Han Sans Neo, Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell,
      Fira Sans, Droid Sans, Helvetica Neue, sans-serif !important;
  }
  input {
    letter-spacing: 0.05rem !important;
  }
  html,
  body {
    font-size: 1em;
    word-break: keep-all;
    overflow-wrap: break-word;
  }
  body {
    &:before,
    &:after {
      display: none;
    }
  }
  #__next {
    position: relative;
    left: unset !important;
    margin: 0 auto !important;
    padding: 24px 0;
    box-shadow: none !important;
  }
  .pc-wrapper {
    display: none;
  }
  .logo {
    cursor: pointer;
  }
`;

export const ThemeForCp = createTheme({
  palette: {
    primary: {
      main: '#0057FF',
    },
    secondary: {
      main: '#4BC500',
      contrastText: '#ffffff',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
          borderRadius: 100,
        },
      },
    },
  },
});

export const ThemeForSmobApp = createTheme({
  palette: {
    primary: {
      main: Color.buttonPrimary,
      contrastText: Color.buttonOnPrimary,
    },
    secondary: {
      main: Color.buttonSecondary,
      contrastText: Color.buttonOnSecondary,
    },
    success: {
      main: Color.textBlue,
      contrastText: Color.buttonOnPrimary,
    },
    error: {
      main: Color.buttonTertiary,
      contrastText: Color.buttonOnTertiary,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'div',
          h2: 'div',
          h3: 'div',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div',
          button: 'div',
          caption: 'div',
          overline: 'div',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          letterSpacing: 1.5,
          lineHeight: '130%',
          fontWeight: 700,
          borderRadius: '0',
          height: 56,
          display: 'flex',
          gap: 8,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          letterSpacing: 0.1,
          lineHeight: '140%',
          fontWeight: 500,
          height: 26,
        },
        label: {
          padding: '0 10px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          background: Color.backgroundSurface,
          height: 56,
          alignItems: 'flex-end',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          letterSpacing: -0.1,
          minHeight: 32,
          minWidth: 0,
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: 24,
          marginRight: 24,
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Pretendard',
      '-apple-system',
      'BlinkMacSystemFont',
      'system-ui',
      'Roboto',
      'Helvetica Neue',
      'Segoe UI',
      'Apple SD Gothic Neo',
      'Noto Sans KR',
      'Malgun Gothic',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '3.75rem',
      letterSpacing: -0.6,
      lineHeight: '130%',
      fontWeight: 400,
    },
    h2: {
      fontSize: '3rem',
      letterSpacing: 0,
      lineHeight: '130%',
      fontWeight: 400,
    },
    h3: {
      fontSize: '2.25rem',
      letterSpacing: 0,
      lineHeight: '125%',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.75rem',
      letterSpacing: 0.15,
      lineHeight: '125%',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.5rem',
      letterSpacing: 0.1,
      lineHeight: '125%',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1.25rem',
      letterSpacing: 0.1,
      lineHeight: '125%',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '1.125rem',
      letterSpacing: -0.15,
      lineHeight: '130%',
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: '1rem',
      letterSpacing: -0.1,
      lineHeight: '130%',
      fontWeight: 400,
    },
    body1: {
      fontSize: '0.875rem',
      letterSpacing: -0.1,
      lineHeight: '140%',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.75rem',
      letterSpacing: -0.1,
      lineHeight: '140%',
      fontWeight: 400,
    },
    button: {
      fontSize: '1rem',
      letterSpacing: 1.5,
      lineHeight: '140%',
      fontWeight: 700,
    },
    caption: {
      fontSize: '0.75rem',
      letterSpacing: 0.1,
      lineHeight: '140%',
      fontWeight: 500,
    },
    overline: {
      fontSize: '0.75rem',
      letterSpacing: 1,
      lineHeight: '140%',
      fontWeight: 500,
    },
  },
});
