import {
  ExposeTag,
  OrderStatus,
  PaymentType,
  UserCouponStatus,
  UserProductStatus,
  UserTicketStatus,
} from 'swingby-shared/dist/enums';

export enum Color {
  textPrimary = '#141414',
  textSecondary1 = '#777879',
  textSecondary2 = '#A0A1A2',
  textDisabled = '#E1E2E3',
  textPrimaryOnDark = '#FFFFFF',
  textSecondaryOnDark1 = '#909192',
  textSecondaryOnDark2 = '#606162',
  textDisabledOnDark = '#303132',
  textPlaceholder = '#B1B2B3',
  textRed = '#E8334F',
  textBlue = '#0078FF',

  buttonPrimary = '#141414',
  buttonOnPrimary = '#FFFFFF',
  buttonSecondary = '#F4F5F6',
  buttonOnSecondary = '#797A7B',
  buttonTertiary = '#E8334F',
  buttonOnTertiary = '#FFFFFF',
  buttonComplementary = '#FFCC01',
  buttonOnComplementary = '#141414',
  buttonDisabled = '#EBECED',
  buttonOnDisabled = '#C1C2C3',

  backgroundSurface = '#FFFFFF',
  backgroundSurfaceDark = '#141414',
  backgroundElevation1 = '#F5F6F7',
  backgroundInputField = '#F5F6F7',
  backgroundInputFieldDisabled = '#F8F9FA',
  backgroundDimmed = '#00000080',
  backgroundDivider = '#E1E2E3',

  brandBlack = '#000000',
  brandWhite = '#FFFFFF',
  brandRed = '#FF2D5A',
  brandYellow = '#FFFF2C',

  background = '#ffffff',
  backgroundGrey = '#f7f8f9',
  backgroundRed = '#a30029',
  ticketRed = '#ff6247',
  ticketBlue = '#0c8af5',
  ticketGrey = '#c5c8ce',
  ticketGreen = '#3dae2b',
  secondaryBackground = '#f5f6f8',
  primary1 = '#141414',
  primary2 = '#2D2D2D',
  secondary1 = '#777777',
  secondary2 = '#C5C8CE',
  lightSecondary2 = '#eeeeee',
  important = '#d70137',
  secondaryImportant = '#ff6247',
  alert = '#e04403',
  alert2 = '#ff6247',
  dark = '#0c27a5',
  kakao = '#f8dd01',
  kakaoBrown = '#391f1f',
  fontColor = primary2,
  blue = '#00b8ff',
  lightBlue = '#00b8ff',
  darkBlue = '#0c27a5',
  purple = '#693dcf',
  green = '#0eca2d',
  cyan = '#2ac1db',
  white = '#ffffff',
  playBlue = '#72ACC6',
}

export enum FontFamily {
  ko = 'Pretendard',
  en = 'Montserrat, Pretendard',
}

export const BORDER_RADIUS = 5;

export enum ModalStatus {
  Success = 'success',
  Error = 'error',
  Confirm = 'confirm',
  Normal = 'normal',
}

export const PaymentTypemap: { [index: string]: string } = {
  [PaymentType.CreditCard]: '신용/체크카드',
  [PaymentType.Cash]: '현금/상품권',
  [PaymentType.HandWritten]: '신용카드 수기',
  [PaymentType.KakaoPay]: '카카오페이',
  [PaymentType.PayLater]: '나중에결제',
};

export const OrderStatusMap: { [index: string]: string } = {
  [OrderStatus.WaitForPayment]: '결제 대기',
  [OrderStatus.Paid]: '예매 완료',
  [OrderStatus.Cancelled]: '예매취소',
};

export const UserCouponStatusMap: { [index: string]: string } = {
  [UserCouponStatus.WaitForPayment]: '결제 대기중',
  [UserCouponStatus.Paid]: '사용 전',
  [UserCouponStatus.Used]: '사용 중',
  [UserCouponStatus.Expired]: '유효기간 만료',
  [UserCouponStatus.Cancelled]: '취소/환불',
};

export const UserTicketStatusMap: { [index: string]: string } = {
  [UserTicketStatus.WaitForPayment]: '결제 대기중',
  [UserTicketStatus.Paid]: '사용가능',
  [UserTicketStatus.Used]: '사용완료',
  [UserTicketStatus.Cancelled]: '취소/환불',
};

export const UserProductStatusMap: { [index: string]: string } = {
  [UserProductStatus.WaitForPayment]: '결제 대기중',
  [UserProductStatus.Paid]: '사용가능',
  [UserProductStatus.Used]: '사용완료',
  [UserProductStatus.Cancelled]: '취소/환불',
};

export const zoneMap: { [index: string]: string } = {
  basic: 'Basic Monster',
  exciting: 'Exciting Monster',
  adventure: 'Adventure Monster',
  digital: 'Digital Monster',
};

export const CouponTagMap: { [index: string]: string } = {
  online: '온라인',
  offline: '현장사용',
};

export const CouponStatusMap: { [index: string]: string } = {
  [UserCouponStatus.WaitForPayment]: '결제 대기중',
  [UserCouponStatus.Paid]: '사용 전',
  [UserCouponStatus.Used]: '사용완료',
  [UserCouponStatus.Expired]: '기간만료',
  [UserCouponStatus.Cancelled]: '취소/환불',
};

export enum NoticeType {
  Expired = 'expired',
  Used = 'used',
}

export const NoticeTypeMap: { [index: string]: string } = {
  [NoticeType.Expired]: '기간만료',
  [NoticeType.Used]: '사용완료',
};

export enum WebViewEventType {
  appleLogin = 'appleLogin',
  NFCRead = 'NFCRead',
  localPush = 'localPush',
  registerMission = 'registerMission',
  saveVideo = 'saveVideo',
  getNotiPermission = 'getNotiPermission',
  getPhotoPermission = 'getPhotoPermission',
  uploadImage = 'uploadImage',
  linkToSetting = 'linkToSetting',
  linkToNfcSetting = 'linkToNfcSetting',
  linkToUrl = 'linkToUrl',
  userData = 'userData',
}

export enum PermissionStatus {
  unavailable = 'unabailable',
  denied = 'denied',
  limited = 'limited',
  granted = 'granted',
  blocked = 'blocked',
}

export enum Permission {
  gallery = 'gallery',
  notification = 'notification',
}

export const PermissionMap: { [index: string]: string } = {
  gallery: '영상',
  notification: '알림',
};

export const ExposeTagMap: { [index: string]: string } = {
  [ExposeTag.High]: '주말/공휴일',
  [ExposeTag.Low]: '평일',
  [ExposeTag.Event]: '이벤트',
};

export const PriceTypeMap: { [index: string]: string } = {
  low: '평일',
  high: '주말',
};

export const ParkNameMap: { [index: string]: string } = {
  RWQ277472: '대전신세계',
  RKZ625564: 'Smob 하남',
};
