import { DefaultSeoProps } from 'next-seo';

const DEFAULT_SEO: DefaultSeoProps = {
  defaultTitle: '스몹 by 스포츠몬스터',
  title: '스몹 by 스포츠몬스터',
  description: '스포츠몬스터가 만든 어른들의 놀이터',
  canonical: 'https://www.smob.co.kr/',
  openGraph: {
    title: '스몹 by 스포츠몬스터',
    site_name: '스몹 by 스포츠몬스터',
    url: 'https://www.smob.co.kr/',
    type: 'website',
    locale: 'ko_KR',
    images: [
      {
        url: 'https://cdn.swingby.us/smob-kakao.png',
        width: 800,
        height: 400,
        alt: '스몹 로고',
      },
    ],
  },
};

export default DEFAULT_SEO;
