import { makeVar } from '@apollo/client';
import { User, Band, Client, Coupon, Park } from 'swingby-shared/dist/interfaces';
import { DocumentType } from './types';

export const isLoggedInVar = makeVar<boolean>(!!globalThis?.localStorage?.getItem('token'));
export const userVar = makeVar<DocumentType<User> | null>(null);
export const bandVar = makeVar<Band | null>(null);
export const parkVar = makeVar<DocumentType<Park> | null>(null);

export const clientVar = makeVar<DocumentType<Client> | null>(null);
export const clientPhoneVar = makeVar<string | null>(globalThis?.localStorage?.getItem('cp_num'));
export const clientCouponVar = makeVar<Coupon | null>(null);
export const clientCouponAmount = makeVar<number | null>(null);
