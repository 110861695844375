export const FB_PIXEL_ID = '690355372255974';

export const pageview = () => {
  if (process.env.NEXT_PUBLIC_PRODUCTION !== 'true') return;
  window.fbq('track', 'PageView');
};

export const event = (name: string, options: facebook.Pixel.CustomParameters = {}) => {
  if (process.env.NEXT_PUBLIC_PRODUCTION !== 'true') return;
  window.fbq('track', name, options);
};
